import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { HomeSkeleton } from "../../../@types/contentful";

import "./Hero.css";

export default function Hero(props: HomeSkeleton) {
    const heroImages = props.heroImages.map((image, i) => {
        return <img
            key={i}
            src={image.fields.file?.url as string}
            alt={image.fields.title as string}
        />;
    });

    return (
        <section className="hero">
            <div className="overview">
                <Overview {...props}/>
                <div className="image-row">{heroImages}</div>
                <AidList {...props}/>
            </div>
            <img
                src={props.heroCover.fields.file?.url as string}
                alt={props.heroCover.fields.title as string}
            />
        </section>
    );
}

function Overview(props: Pick<HomeSkeleton, "heroTitle" | "heroTitleAccent" | "heroDescription">) {
    const description = documentToHtmlString(props.heroDescription);

    return (
        <div className="top">
            <header className="decorative-line">
                <h1>{props.heroTitle}</h1>
                <h1 style={{ color: "var(--accent)" }}>
                    {props.heroTitleAccent}
                </h1>
            </header>
            <p dangerouslySetInnerHTML={{ __html: description }}/>
        </div>
    );
}

function AidList(props: Pick<HomeSkeleton, "heroAidListTitle" | "heroAidList" | "checkmarkIcon">) {
    const listItems = props.heroAidList.map((cell, i) =>
        <li key={i}>
            <i className="checkmark"/>
            {cell}
        </li>
    );

    return (
        <div id="aid-list">
            <label htmlFor="aid-list">{props.heroAidListTitle}</label>
            <ul>
                {listItems}
            </ul>
        </div>
    );
}